.cartTitle{
    color: #707070;
    text-align: center;
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 0.4em;
  }
  .tomKurvText{
    color: rgb(247, 93, 93);
    text-align: center;
    font-size: 2.4em;
    font-weight: 500;
    margin: 0;
    margin-bottom: 0.4em;
  }
  .cartIcon{
    font-size: 3em !important;
    text-align: center;
    margin-top: .8em;
    color: #707070;
  }
  .leggtilvare{
    text-align: center;
  }