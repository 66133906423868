.MuiPaper-elevation4.MuiAppBar-root {
  box-shadow: none;
  background-color: #fff;
  border-bottom: 0.5px solid #70707050;
  border-top: 0.5px solid #70707050;
}
.MuiAppBar-colorPrimary.MuiAppBar-root {
  background-color: #74a8d8;
}

.navigation-wrapper {
  display: flex;
  height: 4em;
}
.icons{
  margin-right: 0.3em; 
}
a {
  text-decoration: none;
}
.adminWeb{
  display: inline;
}