.hasAccess {
  background: lightgreen;
}

.noAccess {
  background: lightcoral;
}

.overflow {
  overflow-x: auto;
  width: "100%";
}
.adminBox {
  margin-top: 2em;
}
.inputKate {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.inputProsjekt {
  margin-right: 1em !important;
}
.adminAddBtn {
  background-color: #3f51b5 !important;
  color: #fff !important;
}
.adminAddBtn:disabled {
  background-color: rgba(219, 219, 219, 0.747) !important;
  color: rgb(168, 168, 168) !important;
}
.topBox {
  margin-top: 1em;
  padding-bottom: 1em !important;
  border-bottom: 1.6px solid #70707085;
}
.Trans {
  background-color: #4caf50;
  width: 100%;
  margin-left: -9em;
}

.edit-field-button {
  font-family: inherit;
  background-color: white;
  border:none;
  box-sizing: border-box;
font-size: 1em;
  font-weight: 400 !important;
  padding: 0 !important;
  text-align: left !important;
  text-transform: none !important;
  margin:0;
}
.edit-field-button:hover {
  /* font-weight: 600 !important; */
  text-decoration: underline;
  color: rgb(30, 33, 41) !important;
  background-color: #eee !important;
  border-radius: 5px;
  /* font-size: 1.05em; */
  cursor: pointer;
}
.edit-field-button-icon{
  color: #535353 !important;
}

.MuiButton-root {
  transition: none !important;
}
.MuiContainer-root {
  
  max-width: 1700px !important;
}
.sokProdukt{
  margin-left: 6em !important;
  margin-top: 0 !important;
}
.btnLagerstatus{
  background-color: #fff !important;
  border-radius: 6px !important;
  border: solid 1px rgb(170, 170, 170);
  padding-top: .35em;
}