.moreinfo-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  border-radius: 12px !important;
}
.moreinfo-title{
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.7);
}
.MuiTypography-h6{
  font-size: 1.15rem !important;
}
.moreinfo-location{
  font-weight: 500;
  font-size: 0.95em;
}
.btns{
 margin-bottom: 1.2em;
 margin-left: 2.2em;
 margin-right: 2.2em;
}
.moreinfo-container22{
  background-color: #F5F7F9;
}
.productDescription{
  margin-top: 1em;
  font-size: 0.86em
}