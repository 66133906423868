.MuiInputBase-root {
  height: 50px;
}
.MuiTextField-root {
  background: white;
}
.createSelect__control {
  background: white;
  height: 50px;
  font-size: 1rem;
  margin-top: 8px;
  margin-bottom: 4px;

  min-height: 50px;
}
.MuiFormControl-marginDense {
  margin-top: 0.8em;
}
.MuiInputBase-root {
  height: auto;
}
.leggTilTitle{
  color: #707070;
  text-align: center;
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 0.6em;
}
.css-yk16xz-control {
  height: auto;
}
.createInput *, *::before, *::after{
  border-radius: 8px !important;
}
.createInput input, #select-category, CreatableSelect{
  background-color: #f8f6f8;
}
.AntallEnhet{
  width: 90% !important;
}
input[type="file"]::-webkit-file-upload-button {
  border: none;
  background: #ffffff00;
  color: #4b4b4b;
  font-weight: 700;
  cursor: pointer;
  border-radius: 0px;
}
input[type="file"]::-ms-browse {
  border: none;
  background: #ffffff00;
  color: #4b4b4b;
  font-weight: 700;
  cursor: pointer;
  border-radius: 0px;
}