.spinner {
  color: "#4DAF7C";
  display: "flex";
  flex: "0 1 auto";
  flex-direction: "column";
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: "25%";
  max-width: "25%";
  height: "200px";
  align-items: "center";
  justify-content: "center";
}
.MuiBox-root-62 {
  /* margin-top: 5em; */
  margin-bottom: 50px !important;
}
.MuiBox-root-215 {
  padding-bottom: 0px !important;
}
.MuiContainer-root {
  padding-bottom: 0px !important;
}
body {
  background-color: #fff;
}
.css-yk16xz-control {
  background-color: #f8f6f8 !important;
}
.top5box {
  background-color: #f6f8fa !important;
  border-radius: 6px !important;
  border: 0.5px solid #70707020 !important;
  color: #414141 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  margin-bottom: -6px !important;
  padding: 0.5em !important;
}
.topdel {
  background-image: url("./backgr.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 16em;
}
.botdel {
  border-radius: 12px !important;
  background: #fff;
  margin-top: -30px;
  padding: 12px;
  padding-bottom: 0px;
}
.innipro {
  padding: 12px !important;
  padding-top: 1em !important;
}
.underRow {
  display: none !important;
  background: lightgray;
}

.underRow.openRow {
  display: table-row !important;
}
.makeStyles-snackbar-261 {
  top: 64px;
  bottom: 0;
}
.MuiTypography-subtitle1 {
  font-weight: 500 !important;
}
.homeContainer {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 1rem;
  overflow-y: scroll;
}

.MuiTypography-h5 {
  font-weight: 500 !important;
}
.leggTilBtn {
  border-radius: 6px;
  background-color: #606fee;
  color: #fff;
  padding: 4px;
  padding-left: 14px;
  padding-right: 14px;
  border: none;
}
.MuiBox-root-62 {
  padding-bottom: 1em !important;
}
#velgpro {
  padding-top: 84px;
  text-align: -webkit-center !important;
}
#dropdownPro {
  width: 280px !important;
  text-align: -webkit-left !important;
}
.prod {
  padding-top: 6px;
  margin-right: 10px;
}
.increaseAmount {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  padding: 3px;
}
.decreaseAmount {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: none;
  padding: 3px;
}
.AmountCounter {
  border: none;
  padding: 3px;
}
.stock {
  display: inline-block;
  border-radius: 2px;
  height: 0.75rem;
  width: 0.75rem;
  margin-right: 0.25rem;
  background-color: #59c129;
}
.yellowStock {
  background-color: #f8df3d;
}

.redStock {
  background-color: #d82931;
}
.listbox {
  text-align: left;
  padding: 3%;
  width: 98.6%;
  background: #f5f7f9 0% 0% no-repeat padding-box;
  float: left;
  margin-top: 17px;
  margin-left: 0.6%;
  margin-right: 0.6%;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
  display: grid;
  grid-template-columns: auto auto;
}
.listofitems li {
  list-style-type: none;
}
.listofitems {
  padding: 0;
}
.leftRow {
  padding-left: 15px;
}
.produktNavn {
  font-size: 14px;
  font-weight: 700;
  color: #707070;
  width: 192px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.produktDiscription {
  font-size: 14px;
  font-weight: 400;
  color: #707070;
  opacity: 0.85;
  margin-top: 6px;
  margin-bottom: 7px;
  max-width: 192px;
  height: 32px;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Produktbilde1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.productQuantity {
  display: inline;
  font-size: 13px;
  font-weight: 400;
  color: #707070;
}
.ProsjektText {
  color: #616161;
  text-align: center;
  font-size: 2.2em;
  font-weight: 500;
  margin-top: -0.5em;
  margin-bottom: 1em;
}
#ofteProsjektText {
  font-size: 1.7em;
}
.prosjectName {
  text-align: center;
  font-size: 1.45rem;
  font-weight: 500 !important;
}
.MuiDialog-paperFullWidth {
  width: calc(100% - 46px) !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.MuiDialog-paper {
  margin: 28px !important;
}